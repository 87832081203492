import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN: string =
    (process.env.SENTRY_DSN as string) || (process.env.NEXT_PUBLIC_SENTRY_DSN as string)

Sentry.init({
    dsn: SENTRY_DSN || 'https://17394f69c6e446bca38aab0c8731de67@o924243.ingest.sentry.io/6157964',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.2,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
})
