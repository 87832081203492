declare global {
  interface Window {
    dataLayer: any
  }
}

const gtm = (event: object) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(event)
}

export default gtm
