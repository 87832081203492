import gtm from '@/lib/utils/gtm'
import * as React from 'react'

const BrowserWarning: React.FunctionComponent<{}> = () => {
  return (
    <>
      <div
        id="browser-warning"
        style={{
          display: 'none',
          background: 'black',
          color: 'white',
          padding: '30px',
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}
      >
        <p>
          Your web browser is too old to view this website.{' '}
          <a
            href="https://browser-update.org/update.html"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              gtm({
                event: 'browser-upgrade-notice-clicked',
              })
            }}
            style={{
              textDecoration: 'underline',
            }}
          >
            Click here to update your browser...
          </a>
        </p>
      </div>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            if (navigator.userAgent.match(/Trident.*rv:11\./)) {
                document.getElementById('browser-warning').style.display = "block";
            }
        `,
        }}
      ></script>
    </>
  )
}

export default BrowserWarning
