import * as React from 'react'

type ModalContextType = {
    showModal: boolean
    dataModal: { [key: string]: any }
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    setDataModal: React.Dispatch<React.SetStateAction<object>>
}

const ModalContext = React.createContext<ModalContextType | null>(null)

export default ModalContext
