import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ModalContext from '@/lib/context/modal'
import convertVideo from '@/lib/utils/embed-video'

import Cross from '@/svg/cross'

type AppModalType = {
    data: any
}

const AppModal: React.FunctionComponent<AppModalType> = ({ data }) => {
    const modalContext = React.useContext(ModalContext)

    if (!modalContext) {
        throw new Error('Missing modal context!')
    }

    const { showModal, setShowModal, dataModal } = modalContext

    return (
        <Transition appear show={showModal} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={() => {
                    setShowModal(false)
                }}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 cursor-pointer bg-black bg-opacity-80">
                            <div className="flex w-full justify-end p-5 sm:p-10">
                                <button
                                    onClick={() => {
                                        setShowModal(false)
                                    }}
                                    aria-labelledby="button-close-label"
                                >
                                    <span className="sr-only" id="button-close-label">
                                        Close
                                    </span>
                                    {Cross(
                                        'fill-white w-5 h-5 sm:w-[30px] sm:h-[30px] transition duration-300 ease-in hover:opacity-30'
                                    )}
                                </button>
                            </div>
                        </Dialog.Overlay>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-[896px] transform overflow-hidden rounded-md text-left align-middle shadow-xl transition-all">
                            <div className="relative h-0 pb-[56.25%] bg-white">
                                {data && data.type === 'video' && data.url && (
                                    <iframe
                                        className="absolute top-0 left-0 w-full h-full"
                                        src={convertVideo(data.url)}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default AppModal
