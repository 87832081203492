import * as React from 'react'
import '../styles/main.css'
import type { AppProps } from 'next/app'
import GoogleTagManager from '@/components/GoogleTagManager'
import BrowserWarning from '@/components/BrowserWarning'
import Head from 'next/head'
import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import ModalContext from '@/lib/context/modal'
import AppModal from '@/components/AppModal'

// FIX: Hydration Error

function MyApp({ Component, pageProps }: AppProps) {
    const [isInitial, setIsInitial] = React.useState(false)
    const [showTransition, setShowTransition] = React.useState(false)
    const [showModal, setShowModal] = React.useState<boolean>(false)
    const [dataModal, setDataModal] = React.useState<object>({})
    const [mounted, setMounted] = React.useState<boolean>()

    React.useEffect(() => {
        setIsInitial(false)
    }, [])

    const router = useRouter()

    React.useEffect(() => {
        setMounted(true)

        window.addEventListener('load', (event) => {
            window.sessionStorage.setItem('visited-site', 'true')
        })

        router.events.on('routeChangeStart', () => {
            setShowTransition(true)
        })

        router.events.on('routeChangeComplete', () => {
            setTimeout(() => {
                setShowTransition(false)
            }, 1000)
        })
    }, [router.events])

    return (
        <>
            {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && <GoogleTagManager />}
            <BrowserWarning />
            <ModalContext.Provider
                value={{
                    dataModal: dataModal,
                    showModal: showModal,
                    setShowModal: setShowModal,
                    setDataModal: setDataModal,
                }}
            >
                <AnimatePresence mode="wait">
                    {mounted && (
                        <motion.div
                            id="#app"
                            key={router.asPath}
                            initial={isInitial ? { opacity: 1 } : { opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="relative overflow-hidden"
                        >
                            <Component {...pageProps} />
                            <AppModal data={dataModal} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </ModalContext.Provider>
        </>
    )
}

export default MyApp
