// SVG Icons

function cross(classes?: string) {
    return (
        <svg
            className={classes}
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.42492 15.7885C0.839132 16.3743 0.839132 17.3241 1.42492 17.9098C2.0107 18.4956 2.96045 18.4956 3.54624 17.9098L9.7207 11.7354L15.9097 17.9244C16.4955 18.5101 17.4452 18.5101 18.031 17.9244C18.6168 17.3386 18.6168 16.3888 18.031 15.803L11.842 9.61406L18.3955 3.0606C18.9813 2.47481 18.9813 1.52507 18.3955 0.93928C17.8097 0.353493 16.8599 0.353494 16.2742 0.93928L9.7207 7.49274L3.18175 0.953788C2.59597 0.368001 1.64622 0.368001 1.06043 0.953788C0.474647 1.53957 0.474647 2.48932 1.06043 3.07511L7.59938 9.61406L1.42492 15.7885Z"
                fill="white"
            />
        </svg>
    )
}

export default cross
